<template>
  <div class="mx-auto" style="max-width: 400px;">
    <h3>Settings</h3>
    <v-checkbox v-model="isShowReporting" label="Show reporting"/>
  </div>
</template>

<script>
export default {
  name: 'Settings',
  computed: {
    isShowReporting: {
      get() {
        return localStorage.getItem('isShowReporting') === 'true';
      },
      set(v) {
        return localStorage.setItem('isShowReporting', v);
      },
    },
  },
};
</script>

<style scoped>

</style>
